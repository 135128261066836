import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import kphuset from '../assets/images/kompetansehuset.jpg';
import kplogo from '../assets/images/kplogo.png';
import flex from '../assets/images/flexboks.png';
import flexBg from '../assets/images/flexBg.jpg';
import flexWhite from '../assets/images/flex_hvit.png';
import hevBg from '../assets/images/hevBg.jpg';
import hev from '../assets/images/hev.png';

import { FloorPage } from './Page';
import { ReactElement } from 'react';
import { CompanyPage } from './CompanyPage';

const FLOORS = [
  {
    name: '1. ETASJE',
    companies: [
      {
        name: '',
        logo: 'https://aider.no/wp-content/uploads/logo-blue-1.svg',
      },
      {
        name: '',
        logo: 'https://iteam.no/wp-content/uploads/2021/11/Logo-positiv_v2.svg',
      },
    ],
    index: 0,
  },
  {
    name: '2. ETASJE',
    companies: [
      {
        name: '',
        logo: 'https://www.nofitromso.no/img/nofitromso/Forside/NOFI-Troms%C3%B8-logo_trans.png',
      },
      {
        name: '',
        logo: 'https://konfliktraadet.no/wp-content/uploads/2021/02/Logo.svg',
      },
      {
        name: 'Ing. Terje Jakobsen AS',
        logo: 'file:///C:/Users/seval/Downloads/Logo%20(2).svg',
      },
      {
        name: 'Mosjøen Fjernvarme',
        logo: '',
      },
      {
        name: 'CableCom AS',
        logo: '',
      },
      {
        name: 'Øynor Consult AS',
        logo: '',
      },
      {
        name: 'Midt Norsk Industri Consult AS',
        logo: '',
      },
    ],
    index: 0,
  },
  {
    name: '3. ETASJE',
    companies: [
      {
        name: '',
        logo: 'https://cdn-cldbi.nitrocdn.com/KiSHEwUJcusVvIqFdfkPmhsIZiYGORmz/assets/images/optimized/rev-a12cae5/wp-content/uploads/2016/03/cropped-logo-2.png',
      },
      {
        name: '',
        logo: 'https://www.nord.no/Style%20Library/Images/nordlogono.jpg',
      },
      {
        name: 'If forsikring Mosjøen',
        logo: 'https://s3-eu-west-1.amazonaws.com/tpd/logos/58c956220000ff00059e8822/0x0.png',
      },
      {
        name: '',
        logo: 'https://telecomnancy.univ-lorraine.fr/wp-content/uploads/2022/10/PwC-PricewaterhouseCoopers-Symbol-PNG.png',
      },
      { name: '', logo: flex },
      { name: '', logo: hev },
      {
        name: 'Mosjøen Fotklinikk',
        logo: '',
      },
    ],
    index: 0,
  },
];

export const SlideShow = () => {
  const pages: ReactElement[] = [
    <FloorPage bgImage={kphuset} logo={kplogo} floors={FLOORS} />,
    <CompanyPage
      name='Flexboks'
      bgImage={flexBg}
      title='Teknologi og Utvikling'
      description='Flexboks er et teknologiselskap som utvikler egne tjenester, samt innovative løsninger for ulike aktører med fokus på bærekraft. Vår ambisjon er å skape Nord-Norges største teknologi og innovasjonsmiljø.'
      logo={flexWhite}
      titleColor='#f9ac46'
      descColor='#fff'
      email='post@flexboks.com'
      phone='+ 47 99357299'
      hours='Man - Fre, 08:00 - 16:00'
      floor='3. etasje'
      floors={FLOORS}
    />,
    <CompanyPage
      name='HEV'
      bgImage={hevBg}
      title='Event og Profilering'
      description='HEV startet som en høytsvevende idé av en gruppe venner i 2007. Siden da har vi vokst og utviklet oss til å bli en av de ledende aktørene i bransjen. Vi jobber i dag for å HEVe privat og offentlig næringsliv over hele landet, med Helgeland som kjerneområde.'
      logo={
        'https://i0.wp.com/helgelandevent.no/wp-content/uploads/2020/11/Helgelandevent_main.png?resize=1000%2C561&ssl=1'
      }
      titleColor='#f45500'
      descColor='#fff'
      email='post@helgelandevent.no'
      phone='(+47) 989 01 242'
      hours='Man - Fre, 08:00 - 16:00'
      floor='3. etasje'
      floors={FLOORS}
    />,
  ];

  return (
    <Slide
      arrows={false}
      indicators={false}
      duration={30000}
      pauseOnHover={false}
      canSwipe={false}
    >
      {pages.map((page) => {
        return page;
      })}
    </Slide>
  );
};
