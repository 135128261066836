import React from 'react';
import { FloorType } from './Page';

export interface CompanyPageType {
  name: string;
  bgImage: string;
  logo?: string;
  title?: string;
  description?: string;
  titleColor?: string;
  descColor?: string;
  phone?: string;
  email?: string;
  hours?: string;
  floor?: string;
  floors?: FloorType[];
}

export const CompanyPage: React.FC<CompanyPageType> = ({
  name,
  bgImage,
  title,
  logo,
  description,
  titleColor,
  phone,
  email,
  hours,
  floor,
  floors,
}) => {
  return (
    <div key='' className='each-slide-effect'>
      <div
        style={{
          background: `url(${bgImage})`,
          zIndex: 1,
          /*   backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'content-box', */
          backgroundSize: 'cover',
          display: 'flex',
          padding: 20,
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '33%',
            flexDirection: 'column-reverse',
          }}
        >
          <div
            style={{
              width: '100%',
              height: 'auto',
              backgroundColor: 'rgba(255,255,255, 0.8)',
              borderRadius: 15,
              margin: 10,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                backgroundColor: titleColor,
                width: '100%',
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              }}
            >
              <p style={{ color: 'white', fontWeight: 'bold' }}>
                {floors?.[0].name}
              </p>
            </div>

            {floors?.[0]?.companies?.map((company, i) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    height: 20,
                    alignItems: 'center',
                    marginLeft: 5,
                    marginRight: 5,
                    /*  borderBottomStyle: 'solid',
                          borderBottomWidth: 0.5, */
                    padding: 5,
                    justifyContent: 'center',
                    borderBottomStyle: 'solid',
                    borderBottomWidth:
                      i === floors?.[0]?.companies?.length - 1 ? 0 : 0.5,
                  }}
                >
                  <img src={company?.logo} alt='' style={{ height: 20 }} />
                  <p
                    style={{
                      marginLeft: 10,
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {company.name}
                  </p>
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '100%',
              height: 'auto',
              backgroundColor: 'rgba(255,255,255, 0.8)',
              borderRadius: 15,
              margin: 10,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                backgroundColor: titleColor,
                width: '100%',
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              }}
            >
              <p style={{ color: 'white', fontWeight: 'bold' }}>
                {floors?.[1].name}
              </p>
            </div>

            {floors?.[1]?.companies?.map((company, i) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    height: 20,
                    alignItems: 'center',
                    marginLeft: 5,
                    marginRight: 5,
                    /*  borderBottomStyle: 'solid',
                          borderBottomWidth: 0.5, */
                    padding: 5,
                    justifyContent: 'center',
                    borderBottomStyle: 'solid',
                    borderBottomWidth:
                      i === floors?.[1]?.companies?.length - 1 ? 0 : 0.5,
                  }}
                >
                  <img src={company?.logo} alt='' style={{ height: 20 }} />
                  <p
                    style={{
                      marginLeft: 10,
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {company.name}
                  </p>
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '100%',
              height: 'auto',
              backgroundColor: 'rgba(255,255,255, 0.8)',
              borderRadius: 15,
              margin: 10,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                backgroundColor: titleColor,
                width: '100%',
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              }}
            >
              <p style={{ color: 'white', fontWeight: 'bold' }}>
                {floors?.[2].name}
              </p>
            </div>

            {floors?.[2]?.companies?.map((company, i) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    height: 20,
                    alignItems: 'center',
                    marginLeft: 5,
                    marginRight: 5,
                    /*  borderBottomStyle: 'solid',
                          borderBottomWidth: 0.5, */
                    padding: 5,
                    justifyContent: 'center',
                    borderBottomStyle: 'solid',
                    borderBottomWidth:
                      i === floors?.[2]?.companies?.length - 1 ? 0 : 0.5,
                  }}
                >
                  <img src={company?.logo} alt='' style={{ height: 20 }} />
                  <p
                    style={{
                      marginLeft: 10,
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    {company.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            width: '66%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img src={logo} alt='logo' style={{ width: 600, height: 'auto' }} />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', fontSize: 18 }}
          >
            <div style={{ color: '#fff', maxWidth: '40%', margin: 20 }}>
              <h2 style={{ color: titleColor }}>{title}</h2>
              <p>{description}</p>
            </div>
            <div style={{ color: '#fff', maxWidth: '40%', margin: 20 }}>
              <h2 style={{ color: titleColor }}>Kontakt oss</h2>
              <p>{`Epost: ${email}`}</p>
              <p>{`Telefon: ${phone}`}</p>
              <p>{hours}</p>
            </div>
          </div>
          <div>
            <h3 style={{ color: titleColor }}>Du finner oss i {floor}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
